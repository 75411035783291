import { Button, Col, Row, Typography, Tooltip, Switch, Card, message, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Prompt } from 'react-router-dom';
import { DownOutlined, LockOutlined, UnlockOutlined, QuestionCircleOutlined, UpOutlined } from '@ant-design/icons';
import confirm from "antd/lib/modal/confirm";
import ApiService from "../../../services/api-services";
import axios from "axios";


// Initial chat sort settings
const chatSortSettings = {
  'First-SAR': { enabled_newest: false, locked_newest: true, display_order: 1, locked_order: true },
  '@-SAR': { enabled_newest: false, locked_newest: true, display_order: 2, locked_order: true},
  'SUB-SAR': { enabled_newest: false, locked_newest: true, display_order: 3, locked_order: true},
};


export default function OrgChatSortSettings(props) {
  const [loading, setLoading] = useState(true);
  // const [orgList, setOrgList] = useState([{ org_id: '9a74cf80-5861-11ea-87c9-91170caf98ab', name: 'ALL Organizations' }]);
  const [orgList, setOrgList] = useState([{}]);
  // const [orgID, setOrgID] = useState('9a74cf80-5861-11ea-87c9-91170caf98ab');
  const [orgID, setOrgID] = useState("");
  const token = sessionStorage.getItem("token");
  const roleID = sessionStorage.getItem("role_id");
  const cancelToken = axios.CancelToken.source();

  const [orgChatSort, setOrgChatSort] = useState(structuredClone(chatSortSettings));
  const [originalOrgChatSort, setOriginalOrgChatSort] = useState(structuredClone(chatSortSettings));

  const changed = useMemo(() => {
    if (JSON.stringify(orgChatSort) !== JSON.stringify(originalOrgChatSort)) {
      return true;
    }

    return Object.keys(orgChatSort).some(key => {
      return (
        orgChatSort[key].enabled_newest !== originalOrgChatSort[key].enabled_newest ||
        orgChatSort[key].locked_newest !== originalOrgChatSort[key].locked_newest ||
        orgChatSort[key].display_order !== originalOrgChatSort[key].display_order ||
        orgChatSort[key].locked_order !== originalOrgChatSort[key].locked_order
      );
    });
  }, [orgChatSort, originalOrgChatSort]);

  const orgChatSortKeys = Object.keys(orgChatSort).sort((a, b) => {
    return orgChatSort[a].display_order - orgChatSort[b].display_order
  })
  console.log('orgChatSort: ', {orgChatSort})
  console.log('orgChatSortKeys: ', {orgChatSortKeys})

  useEffect(() => {
    if (!token) {
      props.history.push("/login");
      window.location.reload(true);
    } else {
      loadOrgList();
      // loadDefaultOrgChatSort();
    }
  }, [token]);


  const loadOrgList = async () => {
    try {
      setLoading(true);
      const Orglist = await ApiService.adddetails.getOrgList(cancelToken);
      if (Orglist?.status === 200) {
        const sortedOrgList = Orglist.data.sort((a, b) => a.name.localeCompare(b.name));
        setOrgList([...orgList, ...sortedOrgList]);
      }
    } catch (error) {
      message.error("Fetching organizations did not go as expected, try again");
    } finally {
      setLoading(false);
    }
  };

  const handleOrgChange = (orgID) => {
    setOrgID(orgID);
    loadOrgChatSort(orgID);
  };

  const organizations = orgList
    .filter((organization) => organization.name !== 'Afya')
    .map((organization) => ({ 'value': organization.org_id, 'label': organization.name }));

  const loadDefaultOrgChatSort = async () => {
    try {
      setLoading(true);
      const all_orgs_id = '9a74cf80-5861-11ea-87c9-91170caf98ab'
      let fetchedOrgChatSort = await ApiService.general.getOrgChatSortSettings({ role_id: roleID, org_id: all_orgs_id}, cancelToken);
      const chatSortSettings = {};
      for (const rule of fetchedOrgChatSort.data) {
        const { trigger, ...ruleInfo } = rule;
        chatSortSettings[trigger] = ruleInfo;
      }
      setOrgChatSort(chatSortSettings);
      setOriginalOrgChatSort(structuredClone(chatSortSettings));
    } catch (error) {
      message.error("Settings could not be loaded, try again");
    } finally {
      setLoading(false);
    }
  };

  const loadOrgChatSort = async (orgID) => {
    try {
      console.log("fetchedOrgChatSort begins")
      setLoading(true);
      let fetchedOrgChatSort = await ApiService.general.getOrgChatSortSettings({ role_id: roleID, org_id: orgID }, cancelToken);
      console.log("fetchedOrgChatSort: ", fetchedOrgChatSort)
      const chatSortSettings = {};
      console.log("chatSortSettings: ", chatSortSettings)
      for (const rule of fetchedOrgChatSort.data) {
        console.log("rule: ", rule)
        const { trigger, ...ruleInfo } = rule;
        chatSortSettings[trigger] = ruleInfo;
      }
      console.log("chatSortSettings: ", chatSortSettings)
      setOrgChatSort(chatSortSettings);
      setOriginalOrgChatSort(structuredClone(chatSortSettings));
    } catch (error) {
      message.error("Settings could not be loaded, try again");
    } finally {
      setLoading(false);
    }
  };

  const handleSwitchChange = (trigger) => {
    setOrgChatSort((previous) => {
      previous[trigger].enabled_newest = !previous[trigger].enabled_newest;
      return { ...previous };
    });
  };

  const toggleNewestLock = (trigger) => {
    setOrgChatSort((previous) => {
      previous[trigger].locked_newest = !previous[trigger].locked_newest;
      return { ...previous };
    });
  };

  const toggleMoveLock = (trigger) => {
    setOrgChatSort((previous) => {
      previous[trigger].locked_order = !previous[trigger].locked_order;
      return { ...previous };
    });
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const data = {
        role_id: roleID,
        org_id: orgID,
        settings: orgChatSort,
      };
      console.log("data: ", data)
      const response = await ApiService.general.updateOrgChatSortSettings(data);
      if (response?.status === 200) {
      // if (response?.success) {
        message.success("Settings saved successfully");
        setOriginalOrgChatSort(structuredClone(orgChatSort));
      }
    } catch (error) {
      message.error("Settings could not be saved, try again");
    } finally {
      setLoading(false);
    }
  };

  // Assuming boxes is an array of objects with a unique display_order
  



  const swapBoxes = (fromBox, toBox) => {
    setOrgChatSort((currentOrgChatSort) => {
      if (!currentOrgChatSort[toBox.key].locked_order) {
      currentOrgChatSort[fromBox.key].display_order = toBox.display_order
      currentOrgChatSort[toBox.key].display_order = fromBox.display_order
      return {...currentOrgChatSort }
      } 
      else {
        console.error("Error: One or both boxes not found in currentOrgChatSort.");
        return {...currentOrgChatSort }// or handle the error as needed
      }
    })

    console.log('swapping boxes started')
  };


  const handleDragStart = (event, box) => {
    if (!orgChatSort[box.key].locked_order) {
    console.log('Drag Started')
    console.log(event); // Check if the event object has dataTransfer
    if (event.dataTransfer) {
      event.dataTransfer.setData("dragContent", JSON.stringify(box));
    }
    }
  };
  
  const handleDragOver = (event) => {
    event.preventDefault();  // Allow dropping
    console.log("Drag Over");
  };
  

  const handleDrop = (event, box) => {
    // if (!orgChatSort[box.key].locked_order) {
    //   return
    // }
    // event.preventDefault(); // Allow drop
    console.log("Drop Event:", event);
  
    // Check if dataTransfer is available and has the correct data
    const dragContent = event.dataTransfer.getData("dragContent");
  
    if (!dragContent) {
      console.error("No drag content found");
      return
    }
      
    try {
        const draggedBox = JSON.parse(dragContent); // Parse the drag content
        
        // Make sure draggedItem has the correct structure
        if (draggedBox?.display_order) {
          console.log("Dragged Item:", draggedBox);
        if (!box.key.locked_order) {
        // Swap the boxes based on display_order
          swapBoxes(draggedBox, box);
        }} else {
          console.error("Invalid drag content:", draggedBox);
        }
      } catch (error) {
        console.error("Failed to parse drag content:", error);
      }
    
  };
  


  const confirmChange = () => {
    confirm({
      title: 'Do you want to save the changes?',
      onOk() {
        handleSave();
      },
      onCancel() { },
    });
  };

  return (
    <div className="chat-sort-settings">
      <Prompt
        when={changed}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Row gutter={[0, 48]}>
        <Col span={18}>
          <h3 className="heading-font">Organization Chat Sort Settings</h3>
        </Col>
        <Col span={6}>
          <Tooltip title="Locking defaults means that lower level roles will not be able to modify these settings." placement="bottom">
            <QuestionCircleOutlined style={{ fontSize: "36px", textAlign: "right" }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 48]}>
        <Col span={8} className="org-col" style={{ 'paddingLeft': '4rem' }}>
          <Select
            showSearch
            placeholder="Select Organization"
            options={organizations}
            optionFilterProp="label"
            onChange={handleOrgChange}
            // value={orgID}
            style={{ width: '15rem', alignContent: 'center' }}
            className="org-select"
          />
        </Col>
      </Row>
      <Row align="middle" style={{'marginBottom': '15px'}}>
        <Col span={12}>
        Type of chat
        </Col>
        <Col span={3}>
        Sort & Lock
        </Col>
        <Col span={7}>
        Lock Display Order
        </Col>
      </Row>



      {orgChatSortKeys
        .map((key, index) => {
          const isLocked = orgChatSort[key]?.locked_order;
          const box = { key, ...orgChatSort[key] }
          return (
            <Card
              bordered={false}
              // style={{ marginBottom: '10px', cursor: 'grab' }} // This adds the grabby hand cursor
              style={{
                marginBottom: '10px',
                cursor: 'grab',
                // display: 'flex',
                // alignItems: 'center', // Centers vertically within the Card
              }}
              draggable={true}
              onDragStart={(event) => handleDragStart(event, box)} // To start grag
              onDragOver={handleDragOver}  //Drag over cards
              onDrop={(event) => handleDrop(event, box)} //Drop card

            >
           
              <Row align="middle">
                {/* Hamburger Menu */}
                <Col span={1}>{index + 1}</Col>
                <Col span={1}>
                  <div style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center', // Centers vertically within the Col
                    alignItems: 'flex-start' // Aligns lines to the left
                  }}>
                    <span style={{ display: 'block', width: '20px', height: '2px', backgroundColor: '#333', marginBottom: '4px' }}></span>
                    <span style={{ display: 'block', width: '20px', height: '2px', backgroundColor: '#333', marginBottom: '4px' }}></span>
                    <span style={{ display: 'block', width: '20px', height: '2px', backgroundColor: '#333' }}></span>
                  </div>
                </Col>
                <Col span={8}>
                  <Tooltip title={`Sort for ${key}`} placement="right">
                    <Typography>{key}</Typography>
                    {/* <Typography>{orgChatSort.key.title}</Typography> */}
                  </Tooltip>
                </Col>
                <Col span={7}>
                  <Switch
                    checkedChildren={<span style={{ color: 'black' }}>Newest</span>}
                    unCheckedChildren={<span style={{ color: 'black' }}>Oldest</span>}
                    checked={orgChatSort[key].enabled_newest}
                    onChange={() => handleSwitchChange(key)}
                    disabled={orgChatSort[key].locked_newest}
                  />
                  <Tooltip title={orgChatSort[key].locked_newest ? "Unlock Sorting Order" : "Lock Sorting Order"}>
                    <Button
                      type="text"
                      icon={orgChatSort[key].locked_newest ? <LockOutlined style={{ color: 'red' }} /> : <UnlockOutlined style={{ color: 'green' }} />}
                      onClick={() => toggleNewestLock(key)}
                    />
                  </Tooltip>
                </Col>
                <Col span={3}>
                  <Tooltip title={orgChatSort[key].locked_order ? "Unlock Order" : "Lock Order"}>
                    <Button
                      type="text"
                      icon={orgChatSort[key].locked_order ? <LockOutlined style={{ color: 'red' }} /> : <UnlockOutlined style={{ color: 'green' }} />}
                      onClick={() => toggleMoveLock(key)}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Card>

          )
        })}

        <Row style={{ display: 'flex', justifyContent: 'center' }} className="button-padding">
          <Button
            title="Save the changes made"
            className="btn-type"
            type="primary"
            disabled={!changed}
            onClick={confirmChange}
          >
            Save
          </Button>
          <Button
            title="Reset the changes made"
            className="btn-type"
            type="primary"
            danger
            disabled={!changed}
            onClick={() => loadOrgChatSort(orgID)}
          >
            Reset
          </Button>
        </Row>
      
    </div>
  );

}