import {
  Button,
  Col,
  Row,
  Typography,
  Tooltip,
  Switch,
  message,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import {
  LockOutlined,
  UnlockOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";
import ApiService from "../../../services/api-services";
import axios from "axios";

const modalities = /** @type const */ ({
  IN_APP: 0,
  SMS: 1,
  EMAIL: 2,
  PUSH: 3
});

// Initial notification settings
const defaultSettings = {
  'SAR': {
    enabled: [false, false, false, false],
    locked: [false, false, false, false],
    lockedBy: [null, null, null, null],
  },
  "SUB-SAR": {
    enabled: [false, false, false, false],
    locked: [false, false, false, false],
    lockedBy: [null, null, null, null],
  },
  "@-SAR": {
    enabled: [false, false, false, false],
    locked: [false, false, false, false],
    lockedBy: [null, null, null, null],
  },
};

const rowHeaders = {
  SAR: "Chat Creation and First Responses",
  "SUB-SAR": "Subsequent Exchanges",
  "@-SAR": "Recommendations and Events",
};

const rowTooltips = {
  SAR: "Notification triggers upon giving recommendations, adding patients, specialists, and other events.",
  "SUB-SAR": "Notification triggers upon subsequent exchanges.",
  "@-SAR":
    "Notification triggers upon giving recommendations, adding patients, specialists, and other events.",
};

const adminRoles = {
  "3f333df6-90a4-4fda-8dd3-9485d27cee39": "Super Admin",
  "3f333df6-90a4-4fda-8dd3-9485d27cee49": "Admin",
  "6ecd8c99-4036-403d-bf84-cf8400f67789": "Session Admin",
};

// Create Roles menu
const roles = [
  {
    value: "3f333df6-90a4-4fda-8dd3-9485d27cee36",
    label: "Submitter Consumer",
  },
  {
    value: "3f333df6-90a4-4fda-8dd3-9485d27cee37",
    label: "Responder Provider",
  },
  {
    value: "6ecd8c99-4036-403d-bf84-cf8400f67836",
    label: "Submitter Provider",
  },
  { value: "6ecd8c99-4036-403d-bf84-cf8400f67769", label: "Responder Staff" },
  { value: "40e6215d-b5c6-4896-987c-f30f3678f608", label: "Submitter Staff" },
];

const getTooltipTitle = (modality, settings, adminRoles) => {
  if (modality === modalities.IN_APP) {
    return "Permanently locked for all roles";
  }
  if (settings.lockedBy[modality]) {
    return `This setting has been locked by a ${
      adminRoles[settings.lockedBy[modality]]
    }`;
  }
  return settings.locked[modality]
    ? "Unlock default for lower level roles"
    : "Lock default for lower level roles";
};

const getLockColor = (modality, settings) => {
  if (modality === modalities.IN_APP) {
    return <LockOutlined style={{ color: "red" }} />;
  }
  return settings.locked[modality] ? (
    <LockOutlined style={{ color: "red" }} />
  ) : (
    <UnlockOutlined style={{ color: "green" }} />
  );
};

const orderNotificationSettings = (settings) => {
  const order = ['SAR', 'SUB-SAR', '@-SAR'];
  const orderedSettings = {};
  order.forEach((key) => {
    if (settings[key]) {
      orderedSettings[key] = settings[key];
    }
  });
  return orderedSettings;
};

export default function OrgNotificationSettings(props) {
  const [loading, setLoading] = useState(false);
  const [orgList, setOrgList] = useState([{}]);
  const [orgID, setOrgID] = useState("");
  const [targetRoleID, setTargetRoleID] = useState("");
  const [changed, setChanged] = useState(false);

  const token = sessionStorage.getItem("token");
  const roleID = sessionStorage.getItem("role_id");
  const userID = sessionStorage.getItem("user_id");
  const cancelToken = axios.CancelToken.source();

  // State for notificationSettings and originalNotificationSettings
  const [notificationSettings, setNotificationSettings] =
    useState(defaultSettings);
  const [originalNotificationSettings, setOriginalNotificationSettings] =
    useState(defaultSettings);

  // Load organizations and notifications on initial render
  useEffect(() => {
    if (!token) {
      props.history.push("/login");
      window.location.reload(true);
    }
    loadOrgList();
  }, []);

  // Loads the list of organizations
  const loadOrgList = async () => {
    try {
      setLoading(true);
      const Orglist = await ApiService.adddetails.getOrgList(cancelToken);
      if (Orglist?.status === 200) {
        const sortedOrgList = Orglist.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setOrgList([...orgList, ...sortedOrgList]);
      }
    } catch (error) {
      message.error("Fetching organizations did not go as expected, try again");
    } finally {
      setLoading(false);
    }
  };

  // Handles organization change
  const handleOrgChange = (orgID) => {
    setOrgID(orgID);
    if (targetRoleID != "") {
      loadNotificationSettings(orgID, targetRoleID);
    }
  };

  // Handles target role change
  const handleRoleChange = (targetRoleID) => {
    setTargetRoleID(targetRoleID);
    if (orgID != "") {
      loadNotificationSettings(orgID, targetRoleID);
    }
  };

  // Create organization dropdown menu
  const organizations = orgList
    .filter((organization) => organization.name !== "Afya") // Filter out "Afya" since it's used as all organizations
    .map((organization) => ({
      value: organization.org_id,
      label: organization.name,
    }));

  // ---------------------- Load notification settings ----------------------
  const loadNotificationSettings = async (orgID, targetRoleID) => {
    try {
      setLoading(true);
      const fetchedNotificationSettings =
        await ApiService.general.getOrgNotificationSettings(
          { role_id: roleID, org_id: orgID, target_role_id: targetRoleID },
          cancelToken
        );
      // const fetchedNotificationSettings = {
      //   data: {
      //     'SAR': {
      //       enabled: [true, false, false],
      //       locked: [true, true, true],
      //       lockedBy: [null, "3f333df6-90a4-4fda-8dd3-9485d27cee39", "3f333df6-90a4-4fda-8dd3-9485d27cee39"],
      //     },
      //     "SUB-SAR": {
      //       enabled: [true, false, true],
      //       locked: [true, false, true],
      //       lockedBy: [null, null, null],
      //     },
      //     "@-SAR": {
      //       enabled: [true, true, true],
      //       locked: [true, true, true],
      //       lockedBy: [null, "3f333df6-90a4-4fda-8dd3-9485d27cee39", null],
      //     },
      //   },
      //   status: 200,
      // };

      const originalNotificationSettings = orderNotificationSettings(JSON.parse(
        JSON.stringify(fetchedNotificationSettings.data))
      );
      if (fetchedNotificationSettings?.status === 200) {
        const orderedSettings = orderNotificationSettings(fetchedNotificationSettings.data);
        setNotificationSettings({ ...orderedSettings });
        setOriginalNotificationSettings({ ...originalNotificationSettings });
      }
    } catch (error) {
      message.error("Settings could not be loaded, try again");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------- Handle Switch Changes ----------------------
  // Handle Switch changes
  const handleSwitchChange = (trigger, modality_position) => {
    setNotificationSettings((previousSettings) => {
      previousSettings[trigger].enabled[modality_position] =
        !previousSettings[trigger].enabled[modality_position];
      return { ...previousSettings };
    });
  };

  // Toggle lock functions
  const toggleLock = (trigger, modality) => {
    const settings = notificationSettings[trigger]
    
    if (settings.lockedBy[modality] || modality === modalities.IN_APP) return;

    setNotificationSettings((previousSettings) => {
      previousSettings[trigger].locked[modality] =
        !previousSettings[trigger].locked[modality];
      return { ...previousSettings };
    });
  };

  // ---------------------- Save Changes ----------------------
  // Save changes
  const handleSave = async () => {
    try {
      setLoading(true);
      let newNotificationSettings = { ...notificationSettings };

      const data = {
        role_id: roleID,
        org_id: orgID,
        target_role_id: targetRoleID,
        user_id: userID,
        settings: newNotificationSettings,
      };
      const response =
        await ApiService.general.updateOrgNotificationSettings(data);
      if (response?.status === 200) {
        message.success("Settings saved successfully");
        // Update originalNotificationSettings to the new saved settings
        setOriginalNotificationSettings(newNotificationSettings);
        setChanged(false);
      }
    } catch (error) {
      message.error("Settings could not be saved, try again");
    } finally {
      setLoading(false);
      loadNotificationSettings(orgID, targetRoleID);
    }
  };

  // Check if notificationSettings has changed
  useEffect(() => {
    if (originalNotificationSettings) {
      setChanged(
        JSON.stringify(notificationSettings) !==
          JSON.stringify(originalNotificationSettings)
      );
    }
  }, [notificationSettings, originalNotificationSettings]);

  // Confirm changes before leaving
  const confirmChange = () => {
    confirm({
      title: "Do you want to save the changes?",
      onOk() {
        handleSave();
      },
      onCancel() {},
    });
  };

  return (
    <div className="notification-settings">
      <Prompt
        when={changed}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Row gutter={[0, 48]}>
        <Col span={18}>
          <h3 className="heading-font">Organization Notification Settings </h3>
        </Col>
        <Col span={6}>
          <Tooltip
            title="Locking defaults means that lower level roles will not be able to modify these settings. These settings will take priority and be selected over other roles' selections."
            placement="bottom"
          >
            <QuestionCircleOutlined
              style={{ fontSize: "36px", textAlign: "right" }}
            />
          </Tooltip>
        </Col>
      </Row>

      <div style={{ display: "flex", flexDirection: "column", gap: 48 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            flexDirection: "row",
            gap: 8,
          }}
        >
          <Select
            showSearch
            placeholder="Select Organization"
            options={organizations}
            optionFilterProp="label"
            onChange={(value) => handleOrgChange(value)}
            style={{ width: "15rem", alignContent: "center" }}
            className="org-select"
          ></Select>
          <Select
            showSearch
            placeholder="Select Role"
            options={roles}
            optionFilterProp="label"
            onChange={(value) => handleRoleChange(value)}
            style={{ width: "15rem", alignContent: "center" }}
            className="org-select"
          ></Select>
        </div>

        <div id="grid">
          <Row gutter={[16, 48]}>
            <Col
              span={8}
              className="notification-col"
              style={{ paddingLeft: "5px" }}
            ></Col>

            <Col span={4}>
              <Typography>In-App</Typography>
            </Col>
            <Col span={4}>
              <Typography>SMS</Typography>
            </Col>
            <Col span={4}>
              <Typography>Email</Typography>
            </Col>
            <Col span={4}>
              <Typography>Push</Typography>
            </Col>
          </Row>

          {/* Populate buttons and locks based on  */}
          {Object.entries(notificationSettings).map(([trigger, settings]) => {
            return (
              <Row gutter={[16, 48]}>
                <Col span={8} className="notification-col">
                  <Tooltip title={rowTooltips[trigger]} placement="right">
                    <Typography>{rowHeaders[trigger]}</Typography>
                  </Tooltip>
                </Col>

                {Object.values(modalities).map((modality) => {
                  return (
                    <Col span={4}>
                      <Switch
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        checked={
                          settings.enabled[modality] ||
                          modality === modalities.IN_APP
                        }
                        onChange={() => handleSwitchChange(trigger, modality)}
                        disabled={
                          settings.lockedBy[modality] ||
                          modality === modalities.IN_APP // lock in_app
                        }
                      />
                      <Tooltip
                        title={getTooltipTitle(modality, settings, adminRoles)}
                      >
                        <Button
                          type="text"
                          icon={getLockColor(modality, settings)}
                          onClick={() => toggleLock(trigger, modality)}
                          disabled={
                            settings.lockedBy[modality] ||
                            modality === modalities.IN_APP // lock in_app
                          }
                        />
                      </Tooltip>
                    </Col>
                  );
                })}
              </Row>
            );
          })}

          {changed && (
            <Row
              style={{ display: "flex", justifyContent: "center" }}
              className="button-padding"
            >
              <Button
                title="Save the changes made"
                className="btn-type"
                id=""
                type="primary"
                onClick={() => confirmChange()}
              >
                Save
              </Button>
              <Button
                title="Reset the changes made"
                className="btn-type"
                id=""
                type="primary"
                danger
                onClick={() => loadNotificationSettings(orgID, targetRoleID)}
              >
                Reset
              </Button>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
}
