import requestServer from "../request-server";
import CONFIG from "../config";

var internals = {};
// internals.login = function (email, password) {
internals.ProvideOrg = function (data) {
  return requestServer(
    "/user_management/provider_org_list",
    data,
    "POST",
    {},
    null,
    null,
    true,
    CONFIG.ADMIN_DOMAIN_NAME
  );
};

internals.Logout = function (data) {
  return requestServer(
    "/user_management/logout",
    data,
    "POST",
    {},
    null,
    null,
    true,
    CONFIG.ADMIN_DOMAIN_NAME
  );
};

internals.search_doc = function (data) {
  return requestServer(
    "/super_admin/search_doc",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.search_doc_for_org = function (data) {
  return requestServer(
    "/super_admin/search_doc_for_org",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.search_user = function (data) {
  return requestServer(
    "/user_management/search_user",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.search_org = function (data) {
  return requestServer(
    "/super_admin/search_org",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.search_close_code_permission = function (data) {
  return requestServer(
    "/super_admin/search_close_code_permission",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.search_admin = function (data) {
  return requestServer(
    "/super_admin/search_admin",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.search_spec = function (data) {
  return requestServer(
    "/super_admin/search_spec",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

// internals.filter = function (data) {
//     return requestServer('/chat/filter_session_for_admin', data, 'POST', {}, null, null, true, CONFIG.ADMIN_DOMAIN_NAME);
// }

internals.provider_staff_map_view = function (data) {
  return requestServer(
    "/user_management/provider_staff_map_view",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.provider_staff_mapping = function (data) {
  return requestServer(
    "/user_management/provider_staff_mapping",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.add_org_activity_maps = function (data) {
  return requestServer(
    "/super_admin/add_org_activity_maps",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.get_org_activity_maps = function (data) {
  return requestServer(
    "/super_admin/get_org_activity_maps",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.get_patient_replies = function () {
  return requestServer(
    "/user_management/patient_replies",
    {},
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.searchUserNotificationSettings = function (data, cancelToken) {
  return requestServer(
    "/super_admin/user_list",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME,
    cancelToken
  );
};

internals.updateUserNotificationSettings = function (data) {
  return requestServer(
    "/super_admin/update_notification_settings",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.getModalityMapping = function (data, cancelToken) {
  return requestServer(
    "/super_admin/get_modality_mapping",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME,
    cancelToken
  );
}

internals.getOrgNotificationSettings = function (data, cancelToken) {
  return requestServer(
    "/super_admin/get_org_notification_settings",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME,
    cancelToken
  );
}

internals.updateOrgNotificationSettings = function (data) {
  return requestServer(
    "/super_admin/update_org_notification_settings",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
}

internals.getOrgChatSortSettings = function (data, cancelToken) {
  return requestServer(
    "/super_admin/get_org_chat_sort_settings",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME,
    cancelToken
  );
}

internals.updateOrgChatSortSettings = function (data) {
  return requestServer(
    "/super_admin/update_org_chat_sort_settings",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
}

export default internals;

